import React, { Component } from 'react'
import {Form, FormGroup} from "react-bootstrap"
import ContentEditable from 'react-contenteditable'
import QuestionTypeDropdown from '../QuestionTypeDropdown/QuestionTypeDropdown'
import QuestionFactory from '../Questions/QuestionFactory'
import QuestionTypes from '../../QuestionTypes'
import './SelectComponent.css'
import axios from "axios"
import {Dropdown} from "semantic-ui-react"
import { FormattedMessage, injectIntl} from 'react-intl'

const {rootUrl} = require("../../../../../../config")

class SelectComponent extends Component {
  constructor(props) {
    super(props)

    /*
      Name is the name of the component as is only shown on the dashboard.
      QuestionText is the actual question at the top of the component.
      Focused specifies whether the component is in edit mode or not.
      Type is the currently selected type of the component.
      Required specifies whether the user taking the survey is required to answer the question.
      Options is an array with the options for multiple choice components.
        - When the type of the component is changed, the options are still saved in the component.
          So when the editor decides to switch back to a multiple choice question, the options are still there.
    */
    this.state = {
      name: (this.props.name ? this.props.name : this.props.intl.formatMessage({id: "component_builder.component_name_placeholder"})),
      questionText: (this.props.questionText
        ? this.props.questionText
        : this.props.intl.formatMessage({id: "component_builder.question_placeholder"})),
      focused: (this.props.focused ? this.props.focused : false),
      type: this.props.questionType,
      required: (typeof this.props.required !== "undefined" ? this.props.required : true),
      options: (this.props.options ? this.props.options : []),
      openOption: (this.props.openOption ? this.props.openOption : null),
      hasWeight: !!(this.props.hasWeight),
      weight: (this.props.weight ? this.props.weight : 1),
      dimensions: this.props.dimensions || [],
      selectedDimension: this.props.selectedDimension ? this.props.selectedDimension : -1
    }
  }

  fetchDimensions() {
    axios.get(rootUrl + '/api/v1/benchmark/dimensions')
      .then((res) => {
        res.data.unshift({id: -1, name: this.props.intl.formatMessage({id: "component_builder.select_dimension"})})
        this.setState({dimensions: res.data})
      })
  }

  // This method sets the component to 'edit' mode.
  focus() {
    this.setState({focused: true}, () => {
      this.props.onFocus()
    })
  }

  // This method changes the component to 'non-edit' mode and passes all changes up to surveyFrame.
  unfocus() {
    this.setState({focused: false},() => {
      this.passSettingsUp()
    })
  }

  // This method passes all options to surveyFrame.
  passSettingsUp() {
    console.log(this.state)
    this.props.onSettingsChanged(this.state)
  }

  // This method changes the component type and passes the new settings up to surveyFrame.
  onChangeType(newType) {
    let weight = this.state.weight
    if (!(newType === QuestionTypes.MultipleChoiceMultiple
        || newType === QuestionTypes.MultipleChoiceSingle
        || newType === QuestionTypes.Dropdown
        || newType === QuestionTypes.Likert)) {
      weight = 1
    }

    this.setState({
      type: newType,
      weight: weight
    }, () => {
      this.passSettingsUp()
    })
  }

  // Options have changed inside the multiple choice component, so update these options in parent components
  // up to surveyFrame.
  onOptionsChangedHandler(options, openOption) {
    if (options) {
    options = options.map((option) => {
      option.translations[this.props.selectedTranslation] = option.value
      return option
    })
  }

    if (openOption) {
      openOption.translations[this.props.selectedTranslation] = openOption.value
    }

    this.setState({
      options: options,
      openOption: openOption
    })
  }

  // onOpenOptionsChangedHandler(openOption) {

  //   if (openOption) {
  //     openOption.translations[this.props.selectedTranslation] = openOption.value
  //   }

  //   this.setState({
  //     openOption: openOption
  //   })
  // }

  changeHasWeight() {
    let oldOptions = this.state.options
    if (!this.state.hasWeight) {
      for (let i = 0; i < oldOptions.length; i++) {
        oldOptions[i].weight = oldOptions.length - 1 - i
      }
    } else {
      for (let i = 0; i < oldOptions.length; i++) {
        oldOptions[i].weight = 0
      }
    }

    this.setState({
      hasWeight: !this.state.hasWeight,
      weight: 1,
      options: oldOptions
    }, () => {
      this.passSettingsUp()
    })
  }

  render() {
    // Generate a random string of 10 characters to create a unique key.
    let randomString = Math.random().toString(36).substring(2, 12) + Math.random().toString(36).substring(2, 12)

    return (
      <div className={this.props.className +
                      " select-component-container" +
                      (this.state.focused ? " focused-border" : "")}>

        <button
          onClick={this.state.focused ? this.unfocus.bind(this) : this.focus.bind(this)}
          className={"btn edit-button"}
        >
          <i className={"fa " + (this.state.focused ? "fa-edit" : "fa-edit")} />
        </button>

        <div className={"row" + (this.state.focused ? "" : " unfocused")}>
          <div className="col-xs-6">
            <Form.Group>
              <Form.Label className={"question-component-label"}><FormattedMessage id={"component_builder.name_of_graph"} defaultMessage={"Naam van grafiek/tabel"} /></Form.Label>
              <Form.Control
                value={this.state.name}
                onChange={(e) => this.setState({name: e.target.value})}
                placeholder={this.props.intl.formatMessage({id: "component_builder.component_name_placeholder"})}
              />
            </Form.Group>
          </div>

          <div className="col-xs-6">
            <Form.Group>
              <Form.Label className={"question-component-label"}><FormattedMessage id={"component_builder.question_type"} defaultMessage={"Vraagtype"} /></Form.Label>
              <QuestionTypeDropdown
                className={"question-component-type"}
                onChange={this.onChangeType.bind(this)}
                type={this.state.type} />
            </Form.Group>
          </div>

          {/*<div className="col-xs-4">
            <Form.Group>
              <Form.Label className={"question-component-label"}><FormattedMessage id={"component_builder.language"} defaultMessage={"Taal"} /></Form.Label>
              <Dropdown
                className={"question-component-type"}
                onChange={this.onChangeType.bind(this)}
                type={this.state.type} />
            </Form.Group>
          </div>*/}
        </div>
        <hr className={"question-component-hr" + (this.state.focused ? "" : " unfocused")} /><br />

        <div className={"full-width"}>
          <FormGroup>
            <h4 className={"col-md-10 header m-b-30"}>
              <ContentEditable
                html={this.state.questionText} // innerHTML of the editable div
                disabled={!this.state.focused}
                className={(this.state.focused ? "editable-text-dark" : "")}
                onChange={(e) => this.setState({questionText: e.target.value})} // handle innerHTML change
              />
            </h4>
            <div className={"component-holder"}>
              {/* Render component based on this.state.type. */}
              <QuestionFactory
                focused={this.state.focused}
                type={this.state.type}
                onOptionsChanged={(options, openOption) => this.onOptionsChangedHandler(options, openOption)}
                options={this.state.options}
                openOption={this.state.openOption}
                hasWeight={this.state.hasWeight}
                weight={this.state.weight}
                selectedTranslation={this.props.selectedTranslation}
              />
            </div>
          </FormGroup>
        </div>

        {/* Render 'weight' checkbox and numeric input, 'required' checkbox and 'delete' button. */}
        <div className={"full-width"}>
          <div className={(this.state.focused ? "" : " unfocused")}>
            <div style={!(this.state.type === QuestionTypes.MultipleChoiceMultiple
            || this.state.type === QuestionTypes.MultipleChoiceSingle
            || this.state.type === QuestionTypes.Dropdown
            || this.state.type === QuestionTypes.Likert) ? {"overflow": "hidden"} : {}}>
              {/* Apply overflow hidden only when just the remove button and required check are available. */}
              <button
                onClick={this.state.focused ? this.unfocus.bind(this) : this.focus.bind(this)}
                className="btn btn-success btn-sm m-l-10 f-right m-l-5"
              >
                <i className="fa fa-check"/> <FormattedMessage id={"ui.check"} defaultMessage={"Bevestigen"} />
              </button>
              
              <button
                className="btn btn-danger btn-sm m-l-10 f-right m-l-5"
                onClick={() => this.props.deleteComponent()}>
                <i className="fa fa-remove"/> <FormattedMessage id={"ui.remove"} defaultMessage={"Verwijderen"} />
              </button>

              <Form.Check
                type={"checkbox"}
                label={this.props.intl.formatMessage({id: "ui.required"})}
                onChange={() => this.setState({required: !this.state.required})}
                id={randomString}
                checked={this.state.required}
                className={"checkbox question-component-label display-inline-block f-right m-t-5 m-b-0"}
              />
            </div>

            {/* Number input for weight with the checkbox to enable it. */
              (this.state.type === QuestionTypes.MultipleChoiceMultiple
                || this.state.type === QuestionTypes.MultipleChoiceSingle
                || this.state.type === QuestionTypes.Dropdown
                || this.state.type === QuestionTypes.Likert) &&
              <>
                <input type={"number"}
                       className={"form-control weight-input m-r-10 f-right"}
                       value={this.state.weight}
                       onChange={(e) => this.setState({weight: e.target.value})}
                       min={0}
                       step={0.01}
                       disabled={!this.state.hasWeight} />

                <Form.Check
                  type={"checkbox"}
                  label={"Gewicht:"}
                  onChange={() => this.changeHasWeight()}
                  id={randomString + randomString}
                  checked={this.state.hasWeight}
                  className={"checkbox question-component-label display-inline-block f-right m-t-5 m-b-0 m-r-5"}
                />
                <Dropdown
                  placeholder='Dimensie'
                  onChange={(e, {value}) => {
                    e.preventDefault()
                    this.setState({selectedDimension: value})
                  }}
                  selection
                  options={this.state.dimensions.map((dimension) => {
                    return {key: dimension.id, text: dimension.name, value: dimension.id }
                  })}
                  defaultValue={this.state.selectedDimension}
                />
              </>
            }
          </div>
        </div>
      </div>
    )
  }
}

export default injectIntl(SelectComponent)
