import React, {Component} from 'react'
import DashboardFrame from '../../../components/Dashboard/DashboardFrame/DashboardFrame'
import './Benchmark.css'
import {Dropdown} from 'semantic-ui-react'
import {withRouter} from 'react-router'
import {Radar} from 'react-chartjs-2'
import axios from "axios"
import {Form} from "react-bootstrap"
import {rootUrl} from '../../../config'
import ColorSchemeObjects from "../../../components/Dashboard/ColorSchemeObjects"
import {FormattedMessage, injectIntl} from 'react-intl'
import DropdownMultiselect from "react-multiselect-dropdown-bootstrap"
import ExportDialog from '../../../components/Dashboard/ExportBenchmark/ExportBenchmark'
import XLSX from 'xlsx'


const surveyDatasetBase = {
  fill: true,
  backgroundColor: "rgba(53, 168, 223, 0.2)",
  borderColor: "rgb(53, 168, 223)",
  pointBackgroundColor: "rgb(53, 168, 223)",
  pointBorderColor: "#fff"
}

const clusterDatasetBase = {
  fill: true,
  backgroundColor: "rgba(40, 204, 135, 0.2)",
  borderColor: "rgb(40, 204, 135)",
  pointBackgroundColor: "rgb(40, 204, 135)",
  pointBorderColor: "#fff"
}

class Benchmark extends Component {
  constructor(props) {
    super(props)

    this.state = {
      selectedMode: 0, // 0 = average, 1 = QI
      data: [],
      possibleSurveys: [],
      possibleClusters: [],
      surveyIds: [],
      clusterId: -1,
      benchmarkMode: 'location',
      startPeriod: undefined,
      endPeriod: undefined,
      timeData: [],
      description: '',
      ClustersExcelData: undefined,
      PeriodArrayOne: undefined,
      PeriodArrayTwo: undefined,
      n: 0,
      openingText: "",
      loading: false
    }
  }

  componentDidMount() {
    this.getBenchmarkData()
    this.getPossibleSurveys()
    this.getPossibleClusters()
    this.getTimeBenchmarkData()
  }


  handleAverageExcelExport() {
    this.setState({
      loading: false
    }, () => {
      axios.post(rootUrl + '/api/v1/excel_benchmark_average', {PeriodOne: this.state.PeriodArrayOne, PeriodTwo: this.state.PeriodArrayTwo, StartPeriod: this.state.startPeriod, EndPeriod: this.state.endPeriod, ClusterData: this.state.ClustersExcelData})
        .then(res => {
          // console.log(res.data)
          var worksheet = XLSX.utils.aoa_to_sheet(res.data);
          var wb = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(wb, worksheet, "Export");

          /* write workbook (use type 'binary') */
          var wbout = XLSX.write(wb, {bookType:'xlsx', type:'binary'});

          /* generate a download */
          function s2ab(s) {
            var buf = new ArrayBuffer(s.length);
            var view = new Uint8Array(buf);
            for (var i=0; i!==s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
            return buf;
          }

          let blob = new Blob([s2ab(wbout)],{type:"application/octet-stream"})

          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = 'export.xlsx'
          link.click();
          this.setState({
            loading:false
          })
        })
        .catch(e => {
          console.log(e)
          this.setState({
            loading:false
          })
        })
    })
  }

  handleScoreExcelExport() {
    this.setState({
      loading: false
    }, () => {
      axios.post(rootUrl + '/api/v1/excel_benchmark_score', {PeriodOne: this.state.PeriodArrayOne, PeriodTwo: this.state.PeriodArrayTwo, StartPeriod: this.state.startPeriod, EndPeriod: this.state.endPeriod, ClusterData: this.state.ClustersExcelData})
        .then(res => {
          console.log(res.data)
          var worksheet = XLSX.utils.aoa_to_sheet(res.data);
          var wb = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(wb, worksheet, "Export");

          /* write workbook (use type 'binary') */
          var wbout = XLSX.write(wb, {bookType:'xlsx', type:'binary'});

          /* generate a download */
          function s2ab(s) {
            var buf = new ArrayBuffer(s.length);
            var view = new Uint8Array(buf);
            for (var i=0; i!==s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
            return buf;
          }

          let blob = new Blob([s2ab(wbout)],{type:"application/octet-stream"})

          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = 'export.xlsx'
          link.click();
          this.setState({
            loading:false
          })
        })
        .catch(e => {
          console.log(e)
          this.setState({
            loading:false
          })
        })
    })
  }

  getBenchmarkData = (ids) => {
    // console.log('Retrieving for ', ids)
    var locale = localStorage.getItem('language_locale');
    axios.post(rootUrl + '/api/v1/benchmark', {
      surveyId: ids || this.state.surveyIds,
      clusterId: this.state.clusterId,
      locale: locale
    }).then((res) => {
      this.setState({
        data: res.data.data,
        n: res.data.n
      })
    }).catch((error) => {
      this.setState({
        data: [],
        n: 0
      })
      console.log(error)
    })
  }

  getTimeBenchmarkData = () => {
    var locale = localStorage.getItem('language_locale');
    if (this.state.surveyIds.length > 0 && this.state.startPeriod && this.state.endPeriod) {
      axios.post(rootUrl + '/api/v1/benchmark_time', {
        surveyId: this.state.surveyIds,
        startPeriod: this.state.startPeriod,
        endPeriod: this.state.endPeriod,
        locale: locale
      }).then((res) => {
        this.setState({ timeData: res.data.data, n: ' ' + res.data.firstN + ', ' + res.data.secondN })
      }).catch(e => {
        console.log(e)
      })
    }
  }

  getPossibleSurveys = () => {
    axios.post(rootUrl + '/api/v1/dashboard/projectssummary')
      .then((res) => {
        this.setState({
          possibleSurveys: res.data.surveys
        })
      })
      .catch(e => console.log(e))
  }

  getPossibleClusters = () => {
    var organization_id = parseInt(localStorage.getItem("organization_id"))

    var array = [198]
    axios.get(rootUrl + '/api/v1/benchmark/clusters_in_settings')
      .then((res) => {
        if (array.includes(organization_id)) {
          this.setState({
            possibleClusters: res.data.clusters,
            description: res.data.description
          })
        } else {
            if (res.data.clusters[0].cluster_name == "5 kliniken Ferring Study") {
              res.data.clusters.shift()
            } else if (res.data.clusters[1].cluster_name == "5 kliniken Ferring Study") {
              res.data.clusters.pop()
            }
        this.setState({
          possibleClusters: res.data.clusters,
          description: res.data.description
        })
      }
      })
      .catch(e => console.log(e))
  }

  surveyChangedHandler(id) {
    this.forceUpdate()
  }

  handleDimensionClick(event) {
    if (event[0] && event[0]._index > -1) {
      sessionStorage.setItem('selectedDimension', this.state.data[event[0]._index].dimension)
      localStorage.setItem('selectedSurvey', this.state.surveyIds[0])
      window.location = '/dashboard'
    }
  }

  getColorSchemeFromName(colorScheme) {
    let scheme
    for (let key in ColorSchemeObjects.schemes) {
      scheme = ColorSchemeObjects.schemes[key]
      if (scheme.name === colorScheme) {
        return scheme.scheme
      }
    }
  }

  renderRadar = () => {
    if (!this.state.data || this.state.data.length === 0) {
      return <div className={"no-survey-cluster-placeholder"}>
        <FormattedMessage id={"benchmark.no_data_to_display"} defaultMessage={"Er is geen data om weer te geven"} />
      </div>
    }

    if (this.state.data === []) return "Geen cluster geselecteerd."

    let labels = []
    let surveyData = []
    let clusterData = []
    let excelData = []

    for (let i = 0; i < this.state.data.length; i++) {
      let dimension = this.state.data[i]

      if(dimension.visible) {
        excelData.push(this.state.data[i])
        labels.push(dimension.dimension)

        if (this.state.selectedMode === 0) {
          surveyData.push(parseFloat(dimension.survey_average_score).toFixed(1))
          clusterData.push(parseFloat(dimension.cluster_average_score).toFixed(1))
        } else {
          surveyData.push(parseFloat(dimension.survey_QI_score).toFixed(1))
          clusterData.push(parseFloat(dimension.cluster_QI_score).toFixed(1))
        }
      }
    }

    this.state.ClustersExcelData = excelData;

    let surveyDataset = surveyDatasetBase
    let clusterDataset = clusterDatasetBase

    if (this.state.selectedMode === 0) {
      surveyDataset.label = this.props.intl.formatMessage({id: "benchmark.average_score_organisation"}) + " " + this.state.possibleSurveys.filter(x => this.state.surveyIds.includes(x.survey_id.toString())).map(x => x.survey_name).join(', ')
      clusterDataset.label = this.props.intl.formatMessage({id: "benchmark.average_score_cluster"}) + " " + this.state.possibleClusters[this.state.possibleClusters.findIndex(x => x.cluster_id === this.state.clusterId)].cluster_name || ''
    } else {
      surveyDataset.label = this.props.intl.formatMessage({id: "benchmark.average_QI_organisation"}) + " " + this.state.possibleSurveys.filter(x => this.state.surveyIds.includes(x.survey_id.toString())).map(x => x.survey_name).join(', ')
      clusterDataset.label = this.props.intl.formatMessage({id: "benchmark.average_QI_cluster"}) + " " + this.state.possibleClusters[this.state.possibleClusters.findIndex(x => x.cluster_id === this.state.clusterId)].cluster_name || ''
    }

    let schemeName =localStorage.getItem("colorScheme") ? localStorage.getItem("colorScheme") : "LiveResearch"

    let colorScheme = this.getColorSchemeFromName(schemeName)

    surveyDataset.data = surveyData
    clusterDataset.borderColor = colorScheme ? colorScheme[0] : "#25dfc1"
    clusterDataset.pointBackgroundColor = colorScheme ? colorScheme[0] : "#25dfc1"
    clusterDataset.pointBorderColor = colorScheme ? colorScheme[0] : "#25dfc1"
    clusterDataset.backgroundColor = 'rgba(0,255,255,0)'
    clusterDataset.data = clusterData
    clusterDataset.borderColor = colorScheme ? colorScheme[5] : "#F26419"
    clusterDataset.pointBackgroundColor = colorScheme ? colorScheme[5] : "#F26419"
    clusterDataset.pointBorderColor = colorScheme ? colorScheme[5] : "#F26419"
    clusterDataset.backgroundColor = 'rgba(0,255,255,0)'

    let radarObject = {
      labels: labels,
      datasets: [surveyDataset, clusterDataset]
    }

    let options = {
      scale: {
        ticks: {
          suggestedMin: 0,
          suggestedMax: 3,
          fontSize: 15,

        },
        scaleLabel: {
          fontSize: 40
        },
        pointLabels: {
          fontSize: 15
        },

      },
      legend: {
        labels: {
          fontSize: 15
        }
      },
      tooltips: {
        mode: 'index',
        callbacks: {
          title : () => null,
          label: function (tooltipItem, data) {
            return data.datasets[tooltipItem.datasetIndex].label + ': ' + parseFloat(tooltipItem.yLabel).toFixed(1)
          }
        }
      }
    }

    return <Radar options={options}
                  data={radarObject}
                  onElementsClick={e => this.handleDimensionClick(e)}
    />
  }

  renderTimeRadar = () => {
    if(!this.state.timeData || this.state.timeData.length === 0 || (this.state.timeData[0].length === 0 && this.state.timeData[1].length === 0)) {
     return <div className={"no-survey-cluster-placeholder"}>
       <FormattedMessage id={"benchmark.no_data_to_display"} defaultMessage={"Er is geen data om weer te geven"} />
     </div>
    }

    let labels = []
    let surveyData = []
    let clusterData = []

    for (let j = 0; j < Math.max(this.state.timeData[0].length, this.state.timeData[1].length); j++) {
      let arr = this.state.timeData[0]
      let arr2 = this.state.timeData[1]
      if(arr && arr[j] && arr[j].visible) {
        labels.push(arr[j].dimension_translation)
      } else if(arr2 && arr2[j] && arr2[j].visible) {
        labels.push(arr2[j].dimension_translation)
      }

      if (this.state.selectedMode === 0) {
        arr && arr[j] && arr[j].visible && surveyData.push(parseFloat(arr[j].average_score).toFixed(1))
        arr2 && arr2[j] && arr2[j].visible && clusterData.push(parseFloat(arr2[j].average_score).toFixed(1))
      } else {
        arr && arr[j] && arr[j].visible && surveyData.push(parseFloat(arr[j].QI_score).toFixed(1))
        arr2 && arr2[j] && arr2[j].visible && clusterData.push(parseFloat(arr2[j].QI_score).toFixed(1))
      }

      function arrayEquals(a, b) {
        return Array.isArray(a) &&
          Array.isArray(b) &&
          a.length === b.length &&
          a.every((val, index) => val === b[index]);
      }

      if(!arrayEquals(this.state.PeriodArrayOne, arr) || !arrayEquals(this.state.PeriodArrayTwo, arr2)) {
        this.setState({
          PeriodArrayOne: arr,
          PeriodArrayTwo: arr2
        })
      }

    }


    let surveyDataset = surveyDatasetBase
    let clusterDataset = clusterDatasetBase

    if (this.state.selectedMode === 0) {
      surveyDataset.label = this.props.intl.formatMessage({id: "benchmark.average_score_organisation"}) + " " + this.state.startPeriod
      clusterDataset.label = this.props.intl.formatMessage({id: "benchmark.average_score_organisation"}) + " " + this.state.endPeriod
    } else {
      surveyDataset.label = this.props.intl.formatMessage({id: "benchmark.average_QI_organisation"}) + " " + this.state.startPeriod
      clusterDataset.label = this.props.intl.formatMessage({id: "benchmark.average_QI_organisation"}) + " " + this.state.endPeriod
    }

    surveyDataset.data = surveyData
    clusterDataset.data = clusterData

    console.log(labels)

    let radarObject = {
      labels: labels,
      datasets: [surveyDataset, clusterDataset]
    }

    let options = {
      scale: {
        ticks: {
          suggestedMin: 0,
          suggestedMax: 3,
          fontSize: 15,

        },
        scaleLabel: {
          fontSize: 40
        },
        pointLabels: {
          fontSize: 15
        },

      },
      legend: {
        labels: {
          fontSize: 15
        }
      },
      tooltips: {
        mode: 'index',
        callbacks: {
          title : () => null,
          label: function (tooltipItem, data) {
            return data.datasets[tooltipItem.datasetIndex].label + ': ' + parseFloat(tooltipItem.yLabel).toFixed(1)
          }
        }
      }
    }

    return <Radar options={options}
                  data={radarObject}
                  onElementsClick={e => this.handleDimensionClick(e)}
    />
  }

  render() {
    return (
      <>
      {/* {this.state.showExportDialog &&
      <ExportDialog
          filterIds={this.state.filterId}
          surveyStart={this.state.surveyStart}
          sliderFrom={this.state.sliderFrom}
          sliderTo={this.state.sliderTo}
          onClose={() => this.setState({showExportDialog: false})}
      />} */}

      <DashboardFrame
        onSurveyChanged={this.surveyChangedHandler.bind(this)}
        title="Dashboard"
        dashboardTitle={this.props.intl.formatMessage({id: "benchmark.benchmark"})}
        onLanguageChanged={(language) => this.props.onLanguageChanged(language)}
        {...this.props}
      >
        <div>
          <div className={"benchmark-card slide-up"}>
            <h4 className="header-title pull-left benchmark-title"><FormattedMessage id={"benchmark.info_title"} defaultMessage={"Benchmark informatie"} /></h4><br/><br/>
            {this.state.description && this.state.description.length > 0 ? this.state.description : <p className={"cluster-label"}>
              <span dangerouslySetInnerHTML={{'__html': this.props.intl.formatMessage({id: "benchmark.info"})}} />
            </p>}
            <br/>
            <br/>

            <button style={{marginRight: 10}} className={this.state.benchmarkMode === 'location' ? 'btn btn-primary' : 'btn btn-outlined'}
                    onClick={() => this.setState({benchmarkMode: 'location', n: 0, PeriodArrayTwo: undefined, PeriodArrayOne: undefined})}>{this.props.intl.formatMessage({id: "benchmark.on_basis_of_cluster"})}</button>

            <button style={{marginRight: 10}} className={this.state.benchmarkMode  === 'time' ? 'btn btn-primary' : 'btn btn-outlined'}
                    onClick={() => this.setState({benchmarkMode: 'time', n: 0})}>{this.props.intl.formatMessage({id: "benchmark.on_basis_of_quarter"})}</button>

            <button className={this.state.benchmarkMode  === 'month' ? 'btn btn-primary' : 'btn btn-outlined'}
                    onClick={() => this.setState({benchmarkMode: 'month', n: 0})}>{this.props.intl.formatMessage({id: "benchmark.on_basis_of_month"})}</button>

          </div>
          <div className={"benchmark-card slide-up"}>
            {this.state.benchmarkMode === 'location' &&
              <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                <span className={"cluster-label"}><FormattedMessage id={"benchmark.selection_text_1"} defaultMessage={"Ik wil graag de vragenlijst van"} /> </span>
                {/*<Dropdown*/}
                {/*  placeholder={this.props.intl.formatMessage({id: "benchmark.select_a_survey"})}*/}
                {/*  selection*/}
                {/*  options={this.state.possibleSurveys.map((option, index) => {*/}
                {/*    return {key: index, text: option.survey_name, value: option.survey_id}*/}
                {/*  })}*/}
                {/*  value={this.props.answer && this.props.options[this.props.answer.option].text}*/}
                {/*  onChange={(e, {value}) => {*/}
                {/*    this.setState({surveyId: value}, () => {*/}
                {/*      this.getBenchmarkData()*/}
                {/*    })*/}
                {/*  }}*/}
                {/*/>*/}

                {this.state.possibleSurveys.length > 0 &&
                  <div style={{width: 300}}>
                    <DropdownMultiselect
                      placeholder={this.props.intl.formatMessage({id: "benchmark.select_a_survey"})}
                      buttonClass={'btn-secondary'}
                      options={this.state.possibleSurveys.map((option) => {
                        return {key: option.survey_id, label: option.survey_name}
                      })}
                      handleOnChange={(selected) => {
                        this.setState({
                          surveyIds: selected
                        })
                        this.getBenchmarkData(selected)
                      }
                      }
                      name="Surveys"
                    />
                  </div>
                }
                <span className={"cluster-label"}> <FormattedMessage id={"benchmark.group_selection_text_2"} defaultMessage={"vergelijken met de cluster"} /></span>
                {this.state.possibleClusters.length > 0 ? <Dropdown
                  placeholder={this.props.intl.formatMessage({id: "benchmark.select_a_cluster"})}
                  selection
                  options={this.state.possibleClusters.map((option, index) => {
                    return {key: index, text: option.cluster_name, value: option.cluster_id}
                  })}
                  value={this.props.answer && this.props.options[this.props.answer.option].text}
                  onChange={(e, {value}) => this.setState({clusterId: value}, () => {
                    this.getBenchmarkData()
                  })}
                /> : <FormattedMessage id={"benchmark.no_group_available"} defaultMessage={" - nog geen groep beschikbaar."} />}
                <span className={"cluster-label"}> <FormattedMessage id={"benchmark.group_selection_text_3"} defaultMessage={" "} /></span>
              </div>
            }


            {this.state.benchmarkMode === 'time' && <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
              <span className={"cluster-label"}><FormattedMessage id={"benchmark.selection_text_1"} defaultMessage={"Ik wil graag de vragenlijst van"} /> </span>
              {this.state.possibleSurveys.length > 0 &&
              <div style={{width: 250}}>
                <DropdownMultiselect
                  placeholder={this.props.intl.formatMessage({id: "benchmark.select_a_survey"})}
                  buttonClass={'btn-secondary'}
                  options={this.state.possibleSurveys.map((option) => {
                    return {key: option.survey_id, label: option.survey_name}
                  })}
                  handleOnChange={(selected) => {
                      this.setState({
                        surveyIds: selected
                      })
                      this.getTimeBenchmarkData()
                    }
                  }
                  name="Surveys"
                />
              </div>
              }
              <span className={"cluster-label"}><FormattedMessage id={"benchmark.time_selection_text_2"} defaultMessage={"vergelijken tijdens de periodes"} /></span>
              <Dropdown
                placeholder={this.props.intl.formatMessage({id: "benchmark.select_a_period"})}
                selection
              options={['2025 Q4','2025 Q3', '2025 Q2', '2025 Q1','2024 Q4','2024 Q3', '2024 Q2', '2024 Q1','2023 Q4', '2023 Q3', '2023 Q2', '2023 Q1', '2022 Q4', '2022 Q3', '2022 Q2', '2022 Q1', '2021 Q4', '2021 Q3', '2021 Q2', '2021 Q1', '2020 Q4', '2020 Q3', '2020 Q2', '2020 Q1', '2019 Q4', '2019 Q3', '2019 Q2', '2019 Q1', '2018 Q4', '2018 Q3', '2018 Q2', '2018 Q1', '2017 Q4', '2017 Q3', '2017 Q2', '2017 Q1', '2016 Q4', '2016 Q3', '2016 Q2', '2016 Q1', '2015 Q4', '2015 Q3', '2015 Q2', '2015 Q1'].map((option, index) => {
                  return {key: index, text: option, value: option}
                })}
                value={this.props.answer && this.props.options[this.props.answer.option].text}
                onChange={(e, {value}) => {
                  this.setState({startPeriod: value}, () => {
                    this.getTimeBenchmarkData()
                  })
                }}
              />

              <span className={"cluster-label"}><FormattedMessage id={"benchmark.and"} defaultMessage={"en"} /></span>
              <Dropdown
                placeholder={this.props.intl.formatMessage({id: "benchmark.select_a_period"})}
                selection
                options={['2025 Q4','2025 Q3', '2025 Q2', '2025 Q1', '2024 Q4','2024 Q3', '2024 Q2', '2024 Q1','2023 Q4', '2023 Q3', '2023 Q2', '2023 Q1', '2022 Q4', '2022 Q3', '2022 Q2', '2022 Q1', '2021 Q4', '2021 Q3', '2021 Q2', '2021 Q1', '2020 Q4', '2020 Q3', '2020 Q2', '2020 Q1', '2019 Q4', '2019 Q3', '2019 Q2', '2019 Q1', '2018 Q4', '2018 Q3', '2018 Q2', '2018 Q1', '2017 Q4', '2017 Q3', '2017 Q2', '2017 Q1', '2016 Q4', '2016 Q3', '2016 Q2', '2016 Q1', '2015 Q4', '2015 Q3', '2015 Q2', '2015 Q1'].map((option, index) => {
                  return {key: index, text: option, value: option}
                })}
                value={this.props.answer && this.props.options[this.props.answer.option].text}
                onChange={(e, {value}) => {
                  this.setState({endPeriod: value}, () => {
                    this.getTimeBenchmarkData()
                  })
                }}
              />
            </div>
          }

          {this.state.benchmarkMode === 'month' && <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
              <span className={"cluster-label"}><FormattedMessage id={"benchmark.selection_text_1"} defaultMessage={"Ik wil graag de vragenlijst van"} /> </span>
              {this.state.possibleSurveys.length > 0 &&
              <div style={{width: 250}}>
                <DropdownMultiselect
                  placeholder={this.props.intl.formatMessage({id: "benchmark.select_a_survey"})}
                  buttonClass={'btn-secondary'}
                  options={this.state.possibleSurveys.map((option) => {
                    return {key: option.survey_id, label: option.survey_name}
                  })}
                  handleOnChange={(selected) => {
                      this.setState({
                        surveyIds: selected
                      })
                      this.getTimeBenchmarkData()
                    }
                  }
                  name="Surveys"
                />
              </div>
              }
              <span className={"cluster-label"}><FormattedMessage id={"benchmark.time_selection_text_2"} defaultMessage={"vergelijken tijdens de maanden"} /></span>
              <Dropdown
                placeholder={this.props.intl.formatMessage({id: "benchmark.select_a_period"})}
                selection
                options={['2024 Aug', '2024 Jul','2024 Jun','2024 May' ,'2024 Apr', '2024 Mar', '2024 Feb', '2024 Jan' ,'2023 Dec', '2023 Nov', '2023 Oct','2023 Sep', '2023 Aug', '2023 Jul','2023 Jun','2023 May' ,'2023 Apr', '2023 Mar', '2023 Feb', '2023 Jan', '2022 Dec', '2022 Nov', '2022 Oct', '2022 Sep', '2022 Aug', '2022 Jul', '2022 Jun', '2022 May', '2022 Apr', '2022 Mar', '2022 Feb', '2022 Jan', '2021 Dec', '2021 Nov', '2021 Oct', '2021 Sep', '2021 Aug', '2021 Jul', '2021 Jun', '2021 May', '2021 Apr', '2021 Mar', '2021 Feb', '2021 Jan', '2020 Dec', '2020 Nov', '2020 Oct', '2020 Sep', '2020 Aug', '2020 Jul', '2020 Jun', '2020 May', '2020 Apr', '2020 Mar', '2020 Feb', '2020 Jan'].map((option, index) => {
                  return {key: index, text: option, value: option}
                })}
                value={this.props.answer && this.props.options[this.props.answer.option].text}
                onChange={(e, {value}) => {
                  this.setState({startPeriod: value}, () => {
                    this.getTimeBenchmarkData()
                  })
                }}
              />

              <span className={"cluster-label"}><FormattedMessage id={"benchmark.and"} defaultMessage={"en"} /></span>
              <Dropdown
                placeholder={this.props.intl.formatMessage({id: "benchmark.select_a_period"})}
                selection
                options={['2024 Aug', '2024 Jul','2024 Jun','2024 May' ,'2024 Apr', '2024 Mar', '2024 Feb', '2024 Jan', '2023 Dec', '2023 Nov', '2023 Oct','2023 Sep', '2023 Aug', '2023 Jul','2023 Jun','2023 May' ,'2023 Apr', '2023 Mar', '2023 Feb', '2023 Jan', '2022 Dec', '2022 Nov', '2022 Oct', '2022 Sep', '2022 Aug', '2022 Jul', '2022 Jun', '2022 May', '2022 Apr', '2022 Mar', '2022 Feb', '2022 Jan', '2021 Dec', '2021 Nov', '2021 Oct', '2021 Sep', '2021 Aug', '2021 Jul', '2021 Jun', '2021 May', '2021 Apr', '2021 Mar', '2021 Feb', '2021 Jan', '2020 Dec', '2020 Nov', '2020 Oct', '2020 Sep', '2020 Aug', '2020 Jul', '2020 Jun', '2020 May', '2020 Apr', '2020 Mar', '2020 Feb', '2020 Jan'].map((option, index) => {
                  return {key: index, text: option, value: option}
                })}
                value={this.props.answer && this.props.options[this.props.answer.option].text}
                onChange={(e, {value}) => {
                  this.setState({endPeriod: value}, () => {
                    this.getTimeBenchmarkData()
                  })
                }}
              />
            </div>
          }

          <br/>

            <Form.Group>
              <Form.Check
                key={0}
                type={"radio"}
                label={this.props.intl.formatMessage({id: "benchmark.average_score"})}
                id={"average-score"}
                name={"radio-group"}
                value={this.props.intl.formatMessage({id: "benchmark.average_score"})}
                checked={this.state.selectedMode === 0}
                onChange={() => {
                  this.setState({selectedMode: 0})
                }}
                className={"radio benchmark-radiobutton"}
              />
              <Form.Check
                key={1}
                type={"radio"}
                label={this.props.intl.formatMessage({id: "benchmark.qi_score"})}
                id={"qi-score"}
                name={"radio-group"}
                checked={this.state.selectedMode === 1}
                onChange={() => {
                  this.setState({selectedMode: 1})
                }}
                className={"radio benchmark-radiobutton"}
              />
            </Form.Group>
            {this.state.selectedMode === 1 ? this.props.intl.formatMessage({id: "benchmark.qi_info"}) : ''}

            <hr className={"benchmark-divider"} />

            {this.state.benchmarkMode === 'month' &&
              <button
              className="btn btn-secondary m-t-5 m-r-5 f-right"
              onClick={() => this.handleScoreExcelExport() }
              disabled={this.state.PeriodArrayTwo == undefined}
              >
              <i className={"fa fa-arrow-alt-circle-down"} /> <FormattedMessage id={"benchmark.qi_export_results"} defaultMessage={"Exporteer QI-score resultaten"} />
              </button>
            }

            {this.state.benchmarkMode === 'month' &&
              <button
              className="btn btn-secondary m-t-5 m-r-5 f-right"
              onClick={() => this.handleAverageExcelExport() }
              disabled={this.state.PeriodArrayTwo == undefined}
              >
              <i className={"fa fa-arrow-alt-circle-down"} /> <FormattedMessage id={"benchmark.average_export_results"} defaultMessage={"Exporteer gemiddelde score resultaten"} />
              </button>
            }

            {this.state.benchmarkMode === 'time'  &&
              <button
              className="btn btn-secondary m-t-5 m-r-5 f-right"
              onClick={() => this.handleScoreExcelExport() }
              disabled={this.state.PeriodArrayTwo == undefined}
              >
              <i className={"fa fa-arrow-alt-circle-down"} /> <FormattedMessage id={"benchmark.qi_export_results"} defaultMessage={"Exporteer QI-score resultaten"} />
              </button>
            }

            {this.state.benchmarkMode === 'time'  &&
              <button
              className="btn btn-secondary m-t-5 m-r-5 f-right"
              onClick={() => this.handleAverageExcelExport() }
              disabled={this.state.PeriodArrayTwo == undefined}
              >
              <i className={"fa fa-arrow-alt-circle-down"} /> <FormattedMessage id={"benchmark.average_export_results"} defaultMessage={"Exporteer gemiddelde score resultaten"} />
              </button>
            }

            {this.state.benchmarkMode === 'location' &&
              <button
              className="btn btn-secondary m-t-5 m-r-5 f-right"
              onClick={() => this.handleScoreExcelExport() }
              disabled={this.state.data.length === 0}
              >
              <i className={"fa fa-arrow-alt-circle-down"} /> <FormattedMessage id={"benchmark.qi_export_results"} defaultMessage={"Exporteer QI-score resultaten"} />
              </button>
            }

            {this.state.benchmarkMode === 'location' &&
              <button
              className="btn btn-secondary m-t-5 m-r-5 f-right"
              onClick={() => this.handleAverageExcelExport() }
              disabled={this.state.data.length === 0 }
              >
              <i className={"fa fa-arrow-alt-circle-down"} /> <FormattedMessage id={"benchmark.average_export_results"} defaultMessage={"Exporteer gemiddelde score resultaten"} />
              </button>
            }


            {/* <button
            className="btn btn-secondary m-t-5 m-r-5 f-right"
            onClick={() => this.handleScoreExcelExport() }
            disabled={this.state.PeriodArrayTwo == undefined || !this.state.possibleClusters}
            >
            <i className={"fa fa-arrow-alt-circle-down"} /> <FormattedMessage id={"benchmark.qi_export_results"} defaultMessage={"Exporteer QI-score resultaten"} />
            </button>

            <button
            className="btn btn-secondary m-t-5 m-r-5 f-right"
            onClick={() => this.handleAverageExcelExport() }
            disabled={this.state.PeriodArrayTwo == undefined}
            >
            <i className={"fa fa-arrow-alt-circle-down"} /> <FormattedMessage id={"benchmark.average_export_results"} defaultMessage={"Exporteer gemiddelde score resultaten"} />
            </button> */}


            <p>n = {this.state.n}</p>
            <br/>
            <br/>


            {this.state.benchmarkMode === 'location' && this.state.surveyIds.length > 0 && this.state.clusterId !== -1 && this.renderRadar()}
            {this.state.benchmarkMode === 'time' && this.state.surveyIds.length > 0 && this.state.startPeriod && this.state.endPeriod && this.renderTimeRadar()}
            {this.state.benchmarkMode === 'month' && this.state.surveyIds.length > 0 && this.state.startPeriod && this.state.endPeriod && this.renderTimeRadar()}



            {(this.state.surveyIds.length === 0 || this.state.clusterId === -1) && this.state.benchmarkMode === 'location' &&
            <div className={"no-survey-cluster-placeholder"}>
              <FormattedMessage id={"benchmark.select_survey_and_cluster"} defaultMessage={"Selecteer een vragenlijst en een organisatiecluster."} />
            </div>}

            {!(this.state.surveyIds.length > 0 && this.state.startPeriod && this.state.endPeriod) && this.state.benchmarkMode === 'time' &&
            <div className={"no-survey-cluster-placeholder"}>
              <FormattedMessage id={"benchmark.select_survey_and_periods"} defaultMessage={"Selecteer een vragenlijst en twee tijdsperiodes."} />
            </div>}

            {!(this.state.surveyIds.length > 0 && this.state.startPeriod && this.state.endPeriod) && this.state.benchmarkMode === 'month' &&
            <div className={"no-survey-cluster-placeholder"}>
              <FormattedMessage id={"benchmark.select_survey_and_periods"} defaultMessage={"Selecteer een vragenlijst en twee tijdsperiodes."} />
            </div>}
            <br/>
            <br/>

          </div>
        </div>
      </DashboardFrame>
      </>
    )
  }
}

export default withRouter(injectIntl(Benchmark))

