import React, {Component} from 'react'
import CardBase from '../CardBase/CardBase'
import './GraphCard.css'
import {Bar, Line, Pie} from 'react-chartjs-2'
import scrollToComponent from 'react-scroll-to-component'
import {FormattedMessage} from 'react-intl'
import injectIntl from "react-intl/lib/src/components/injectIntl"

class GraphCard extends Component {
  constructor(props) {
    super(props)
    this.cardRef = React.createRef()
    this.chartRef = React.createRef()


    let dataObject = this.getSortedLikert(this.props.labels, this.props.data)

    if (this.props.chartType === 'bar') {
      this.state = {
        chartData: {
          labels: dataObject.labels,
          datasets: [{
            backgroundColor: this.getColors(1)[0],
            borderColor: this.getColors(1)[0],
            data: dataObject.data,
          }]
        }
      }
    } else if (this.props.chartType === 'pie') {
      this.state = {
        chartData: {
          labels: dataObject.labels,
          datasets: [{
            data: dataObject.data,
            backgroundColor: this.getColors(this.props.labels.length)
          }]
        }
      }
    } else if (this.props.chartType === 'line') {
      // let lang = this.props.intl.formatMessage({id: "misc.slider_language"}) //e.g. "nl-NL"

      // dataObject.labels.map((x) => {
      //   var [year, month] = dataObject.labels[x].split('/');
      //   var result = [month, year].join('/');
      //   console.log(result); // 👉️ "09/24/2022"
      // })
      this.state = {
        chartData: {
          labels: dataObject.labels[0],
          // .map(x => new Date(x).toLocaleDateString(lang, {
          //   year: 'numeric',
          //   month: 'long'
          // })),
          datasets: [{
            label: props.intl.formatMessage({id: "dashboard.number_of_answers"}),
            data: dataObject.data.finished,
            backgroundColor: "#00ebc4",
            borderColor: "#00ebc4",
            fill: false
          }
          // , {
          //   label: props.intl.formatMessage({id: "dashboard.number_of_answers_not"}),
          //   backgroundColor: "#f05400",
          //   borderColor: "#f05400",
          //   fill: false,
          //   data: dataObject.data.unfinished,
          // }
        ]
        }
      }
    }

    this.state = {
      tableVisible: this.props.tableVisible,
      chartData: this.state.chartData,
      blockWidth: this.props.blockWidth,
      blockHeight: this.props.blockHeight
    }
  }

  getSortedLikert(labels, values) {
    let dutchLabels = ["Helemaal niet mee eens", "Niet mee eens", "Neutraal", "Mee eens", "Helemaal mee eens"]
    let germanLabels = ["Stimme überhaupt nicht zu", "Stimme nicht zu", "Neutral", "Stimme zu", "Stimme vollkommen zu"]

    let valueMap = {}
    for (let i = 0; i < labels.length; i++) {
      valueMap[labels[i]] = values[i]
    }

    let sortedLabels = labels
    if (dutchLabels.every(item => labels.includes(item))) {
      sortedLabels = dutchLabels.concat(labels.filter(item => !dutchLabels.includes(item)))
    } else if (germanLabels.every(item => labels.includes(item))) {
      sortedLabels =  germanLabels.concat(labels.filter(item => !germanLabels.includes(item)))
    } else {
      return {labels: labels, data: values}
    }

    let sortedData = []
    for (let j = 0; j < sortedLabels.length; j++) {
      sortedData.push(valueMap[sortedLabels[j]])
    }

    return {labels: labels, data: values}
  }

  getColors(count) {
    return this.props.colorScheme.slice(0, count)
  }

  handlePieClick(event) {
    this.props.onFilterClick(event, this.props.index)
  }

  downloadChart() {
    let el = this.chartRef.current.chartInstance.canvas
    let destinationCanvas = document.createElement("canvas")
    destinationCanvas.width = el.width
    destinationCanvas.height = el.height

    let destCtx = destinationCanvas.getContext('2d')
    destCtx.fillStyle = "#FFFFFF"
    destCtx.fillRect(0, 0, el.width, el.height)
    destCtx.drawImage(el, 0, 0)

    const a = document.createElement('a')
    a.download = this.props.cardTitle + ".png"
    a.href = destinationCanvas.toDataURL('image/png')
    document.body.appendChild(a)
    a.addEventListener('click', function () {
      a.parentNode.removeChild(a)
    })
    a.click()
  }

  getChart() {
    if (this.props.chartType === 'bar') {
      return <Bar ref={this.chartRef}
                  data={this.state.chartData}
                  options={{
                    responsive: true,
                    maintainAspectRatio: false,
                    responsiveAnimationDuration: 0,
                    legend: {
                      display: false
                    },
                    tooltips: {
                      callbacks: {
                        title: function() {},
                        label: function(tooltipItem, data) {
                          let label = data.labels[tooltipItem.index] || '';
                          let currentValue = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]
                          let total = data.datasets[tooltipItem.datasetIndex].data.reduce((a, b) => a + b, 0)

                          let percentage = Math.round(currentValue / total * 100)
                          if (label) {
                            label += ': ';
                          }
                          label += currentValue

                          label += ' ('
                          label += percentage
                          label += '%)'

                          return label;
                        }
                      }
                    }

                  }}/>
    } else if(this.props.chartType === 'line') {
      return <Bar ref={this.chartRef}
                   data={this.state.chartData}
                   options={{
                     responsive: true,
                     maintainAspectRatio: false,
                     legend: {
                       display: true,
                     },
                     scales: {
                       yAxes: [{
                         stacked: true,
                         ticks: {
                           suggestedMax: 10,
                           suggestedMin: 0
                         }
                       }],
                       xAxes: [{
                         stacked: true
                       }]
                     }
                   }}/>
    } else {
      return <Pie ref={this.chartRef}
                  data={this.state.chartData}
                  options={{
                    responsive: true,
                    maintainAspectRatio: false,
                    responsiveAnimationDuration: 0,
                    tooltips: {
                      callbacks: {
                        label: function(tooltipItem, data) {
                          let label = data.labels[tooltipItem.index] || '';
                          let currentValue = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]
                          let total = data.datasets[tooltipItem.datasetIndex].data.reduce((a, b) => a + b, 0)

                          let percentage = Math.round(currentValue / total * 100)
                          if (label) {
                            label += ': ';
                          }
                          label += currentValue

                          label += ' ('
                          label += percentage
                          label += '%)'

                          return label;
                        }
                      }
                    }
                  }}
                  onElementsClick={e => this.handlePieClick(e)}
      />
    }
  }

  toggle() {
    let newWidth = (!this.state.tableVisible ? 4 : this.props.blockWidth)
    let newHeight = (!this.state.tableVisible ? 2 : this.props.blockHeight)

    this.setState({
      tableVisible: !this.state.tableVisible,
      blockWidth: newWidth,
      blockHeight: newHeight
    }, () => {
      this.cardRef.current.sizeChanged(newWidth, newHeight, () => {
        this.chartRef.current.chartInstance.resize() // Redraw chart when toggled

        if (this.state.tableVisible) {
          scrollToComponent(this.cardRef.current)
        }
      })
    })
  }

  getTableBody() {
    if (this.props.chartType === 'line') {
      let labels = this.state.chartData.labels
      let category = this.state.chartData.datasets[0].category
      let numbers1 = this.state.chartData.datasets[0].data
      // let numbers2 = this.state.chartData.datasets[1].data
      // let total = numbers2.reduce((partial_sum, a) => partial_sum + a, 0)
      let total1 = numbers1.reduce((partial_sum, a) => partial_sum + a, 0)
      let percentage_total = total1;
      let result = []

      for (let i = 0; i < labels.length; i++) {
        result.push(<tr key={i}>
          <td>{labels[i]}</td>
          {category &&
                <td>{category[i]}</td>}
          <td>{numbers1[i]}</td>
          {/* <td>{numbers2[i]}</td> */}
          <td>{(numbers1[i] / percentage_total * 100).toFixed(1)}%</td>
        </tr>)
      }

      result.push(<tr key={labels.length}>
        { this.state.chartData.datasets[0].category &&
        <th></th> }
        <th></th>
        <th>{total1}</th>
        {/* <th>{total}</th> */}
        <th>100%</th>
      </tr>)

      return result
    } else {
      let labels = this.state.chartData.labels
      let numbers = this.state.chartData.datasets[0].data
      let total = numbers.reduce((partial_sum, a) => partial_sum + a, 0)
      let result = []

      for (let i = 0; i < labels.length; i++) {
        result.push(<tr key={i}>
          <td>{labels[i]}</td>
          <td>{numbers[i]}</td>
          <td>{(numbers[i] / total * 100).toFixed(1)}%</td>
        </tr>)
      }

      result.push(<tr key={labels.length}>
        <th></th>
        <th>{total}</th>
        <th>100%</th>
      </tr>)

      return result
    }
  }

  returnAverage() {
    if(this.props.chartType === 'bar') {
      let total = 0
      let totalRespondents = this.state.chartData.datasets[0].data.reduce((a, b) => a+b, 0)


      this.state.chartData.labels.map((label, i) => {
        total += this.state.chartData.labels[i] * this.state.chartData.datasets[0].data[i]
      })

      let average = (total / totalRespondents).toFixed(1)
      return ' (' + this.props.intl.formatMessage({id: 'dashboard.average_number'}) + ': ' + average + ')';
    } else {
      return ''
    }
  }

  render() {
    return (
      <CardBase
        ref={this.cardRef}
        blockHeight={this.state.blockHeight}
        blockWidth={this.state.blockWidth}
        cardTitle={this.props.index === 0 ? this.props.cardTitle :this.props.index + '. ' + this.props.cardTitle.replace('&nbsp;', ' ') + this.returnAverage()}
        dimensionTitle={this.props.dimensionTitle}
        optionButtons={
          [
            <span onClick={this.toggle.bind(this)}
                  key={1}
                  style={{visibility: this.state.chartData.datasets[0].data.reduce((a, b) => a + b, 0) > 0 ? 'visible' : 'hidden'}}
                  className={"optionButton fa fa-" + (this.state.tableVisible ? "minus" : "plus")}/>,
            <span onClick={this.downloadChart.bind(this)}
                  key={2}
                  className="optionButton fa fa-download"/>
          ]
        }
        // cardText={this.props.cardText.replace('&nbsp;', ' ')}
        >
        <div className={"result-content"}>
          <div className={"graphWrapper " + (this.state.tableVisible ? "expanded2" : "")}>
            {this.state.chartData.datasets[0].data.reduce((a, b) => a + b, 0) > 0 ? this.getChart() :
              <p>Nog geen data beschikbaar.</p>}
          </div>
          {this.state.tableVisible &&
          <div className="tableWrapper expanded2">
            <table className="table table-striped table-bordered resultsTable">
              <thead>
              { this.props.chartType === 'line' &&
              <tr>
                <th scope="col"><FormattedMessage id={"dashboard.answer"} defaultMessage={"Antwoord"} /></th>
                <th scope="col"><FormattedMessage id={"dashboard.number_of_answers"} defaultMessage={"Aantal afgemaakt"} /></th>
                {/* <th scope="col"><FormattedMessage id={"dashboard.number_of_answers_not"} defaultMessage={"Aantal afgemaakt"} /></th> */}
                <th scope="col"><FormattedMessage id={"dashboard.percentage"} defaultMessage={"Percentage"} /></th>
              </tr>
              }
              { this.props.chartType !== 'line' &&
              <tr>
                <th scope="col"><FormattedMessage id={"dashboard.answer"} defaultMessage={"Antwoord"} /></th>
                <th scope="col"><FormattedMessage id={"dashboard.number_of_answers"} defaultMessage={"Aantal afgemaakt"} /></th>
                <th scope="col"><FormattedMessage id={"dashboard.percentage"} defaultMessage={"Percentage"} /></th>
              </tr>
              }
              </thead>
              <tbody>
              {this.getTableBody()}
              </tbody>
            </table>
          </div>
          }
        </div>
      </CardBase>
    )
  }
}

export default injectIntl(GraphCard)
