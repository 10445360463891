import React, { Component } from 'react'
import DashboardFrame from '../../../components/Dashboard/DashboardFrame/DashboardFrame'
import './NewMailing.css'
import {Redirect, withRouter} from 'react-router'
import {Button, Form} from "react-bootstrap"
import {SketchPicker} from "react-color"
import {NavLink} from "react-router-dom"
import axios from "axios"
import {rootUrl} from "../../../config"
import Swal from "sweetalert2"
import {Dropdown} from "semantic-ui-react"
import SurveyDropdown from "../../../components/Miscellaneous/SurveyDropdown/SurveyDropdown"
import EmailPreviewDialog from "../../../components/Dashboard/EmailPreviewDialog/EmailPreviewDialog"
import {FormattedMessage, injectIntl} from "react-intl"

class NewMailing extends Component {
  constructor(props) {
    super(props)

    const sampleMessage = this.props.intl.formatMessage({id: "new_broadcast.message_content"})

    this.state = {
      editMode: this.props.editMode,
      senderName: "",
      senderEmail: "",
      subject: "",
      buttonColor: "#039cfd",
      surveyId: -1,
      recipients: "",
      recipientsInFile: [],
      title: this.props.intl.formatMessage({id: "new_broadcast.title_content"}),
      message: sampleMessage,
      displayColorPicker: false,
      displayEmailPreview: false,
      redirect: false,
      file: null,
      senderEmailEnd: '@liveresearch.com'
    }

    this.fileUpload = React.createRef()
  }

  handleClick = () => {
    this.setState({ displayColorPicker: !(this.state.displayColorPicker) })
  }

  handleClose = () => {
    this.setState({ displayColorPicker: false })
  }

  // This method saves the color in the state in hex value and passes it up to the surveyFrame.
  handleColorChange = (color) => {
    this.setState({ buttonColor: color.hex })
  }

  saveMailing() {
    let locale = this.props.intl.formatMessage({id: "misc.locale"})

    if (this.state.senderName === "" || this.state.senderEmail === "" || this.state.subject === "" ||
        this.state.title === "" || this.state.message === "" || this.state.surveyId === -1) {
      Swal.fire({
        title: this.props.intl.formatMessage({id: "dialogs.warning_title"}),
        text: this.props.intl.formatMessage({id: "dialogs.broadcasts_all_fields_required"}),
        type: 'info',
        confirmButtonText: 'OK'
      })
    } else if (this.state.recipients === "" && this.state.recipientsInFile.length === 0) {
      Swal.fire({
        title: this.props.intl.formatMessage({id: "dialogs.warning_title"}),
        text: this.props.intl.formatMessage({id: "dialogs.no_recipients_specified"}),
        type: 'info',
        confirmButtonText: 'OK'
      })
    } else {
      axios.post(rootUrl + '/api/v1/broadcast/add_broadcast',
        {
          toEmail: this.state.recipients + (this.state.recipientsInFile ? ";" + this.state.recipientsInFile.join(";") : ""),
          fromEmail: this.state.senderEmail + this.state.senderEmailEnd,
          fromName: this.state.senderName,
          subject: this.state.subject,
          title: this.state.title,
          message: this.state.message,
          buttonColor: this.state.buttonColor,
          backgroundColor: this.state.backgroundColor,
          logo: null,
          surveyId: this.state.surveyId,
          language: locale
        }
      ).then(() => {
        Swal.fire({
          title: this.props.intl.formatMessage({id: "dialogs.success"}),
          text: this.props.intl.formatMessage({id: "dialogs.broadcast_created"}),
          type: 'success',
          confirmButtonText: 'OK'
        }).then(() => {
          this.setState({redirect: true})
        })
      }).catch((error) => {
        console.log(error)
        Swal.fire({
          title: this.props.intl.formatMessage({id: "dialogs.error_title"}),
          text: this.props.intl.formatMessage({id: "dialogs.error_message"}),
          type: 'error',
          confirmButtonText: 'OK'
        })
      })
    }
  }

  renderRedirect = () => {
    if (this.state.redirect) return <Redirect to='/verzendlijsten' />
  }

  handleFileChosen(file) {
    this.setState({
      file: file
    }, () => {
      let fileReader = new FileReader()
      fileReader.onloadend = (e) => {
        let regex = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/gi
        let content = e.currentTarget.result
        let matches = content.match(regex)
        if (matches === null) {
          matches = []
        }

        this.setState({
          recipientsInFile: matches
        })
      }
      fileReader.readAsText(file)
    })
  }

  render() {
    return (
      <DashboardFrame
        title={this.props.intl.formatMessage({id: "new_broadcast.new_broadcast"})}
        dashboardTitle={this.props.intl.formatMessage({id: "new_broadcast.new_broadcast"})}
        onLanguageChanged={(language) => this.props.onLanguageChanged(language)}>
        {this.renderRedirect()}
        <div className="card-box slide-up">
          <div className="col-12">
            <h4 className="header-title m-t-0">
              {(this.props.editMode
                ? this.props.intl.formatMessage({id: "new_broadcast.edit_broadcast"})
                : this.props.intl.formatMessage({id: "new_broadcast.new_broadcast"}))}</h4>
            <p className="text-muted font-13 m-b-30"><FormattedMessage id={"new_broadcast.info"} defaultMessage={"Hier kunt u een verzendlijst aanmaken die ontvangers uitnodigt een vragenlijst in te vullen."} /></p>

            <div className="row">
              <div className="col-xs-6">
                <Form.Group>
                  <Form.Label><FormattedMessage id={"new_broadcast.name_sender"} defaultMessage={"Naam afzender"} /></Form.Label>
                  <Form.Control
                    value={this.state.senderName}
                    onChange={(e) => this.setState({senderName: e.target.value})}
                    required
                    placeholder={this.props.intl.formatMessage({id: "new_broadcast.name_sender_placeholder"})}
                    tabIndex={"1"}
                  />
                </Form.Group>

                <Form.Group>
                  <Form.Label><FormattedMessage id={"new_broadcast.subject"} defaultMessage={"Onderwerp"} /></Form.Label>
                  <Form.Control
                    value={this.state.subject}
                    onChange={(e) => this.setState({subject: e.target.value})}
                    placeholder={this.props.intl.formatMessage({id: "new_broadcast.subject_placeholder"})}
                    tabIndex={"5"}
                  />
                </Form.Group>

                <Form.Group>
                  <Form.Label><FormattedMessage id={"new_broadcast.color_of_buttons"} defaultMessage={"Kleur van de knoppen"} /></Form.Label><br />
                  <div className="settings-swatch" onClick={ this.handleClick }>
                    <div className="settings-colorbox" style={{background: this.state.buttonColor}} />
                  </div>
                </Form.Group>
              </div>

              <div className="col-xs-6">
                <Form.Group>
                  <Form.Label><FormattedMessage id={"new_broadcast.email_sender"} defaultMessage={"E-mail afzender"} /></Form.Label>
                  <div style={{width: "50%", display: 'flex', flexDirection: 'row'}}>
                    <Form.Control
                      value={this.state.senderEmail}
                      onChange={(e) => this.setState({senderEmail: e.target.value})}
                      required
                      placeholder={this.props.intl.formatMessage({id: "new_broadcast.email_sender_placeholder"})}
                      className={"email-textbox"}
                      tabIndex={"2"}
                    />
                    <Dropdown
                      placeholder={this.props.intl.formatMessage({id: "new_broadcast.email_domain_placeholder"})}
                      selection
                      onChange={(e, {value}) => {
                        this.setState({senderEmailEnd: value})
                      }}
                      options={[
                        {key: '@liveresearch.com', value: '@liveresearch.com', text: '@liveresearch.com'},
                        {key: '@satisferti.nl', value: '@satisferti.nl', text: '@satisferti.nl'},
                        {key: '@satisferti.com', value: '@satisferti.com', text: '@satisferti.com'},
                        {key: '@satisferti.de', value: '@satisferti.de', text: '@satisferti.de'},
                        {key: '@satisferti.be', value: '@satisferti.be', text: '@satisferti.be'},
                        {key: '@satisferti.ch', value: '@satisferti.ch', text: '@satisferti.ch'},
                        {key: '@satisferti.at', value: '@satisferti.at', text: '@satisferti.at'}]
                      }
                      value={this.props.answer && this.props.options[this.props.answer.option].text}
                      className={"full-width"}
                    />
                  </div>
                </Form.Group>

                <Form.Group>
                  <Form.Label><FormattedMessage id={"new_broadcast.survey"} defaultMessage={"Vragenlijst"} /></Form.Label><br />
                  <SurveyDropdown className={"full-width"} active={true} onChange={(id) => this.setState({surveyId: id})} />
                </Form.Group>
              </div>
            </div>

            <div className={"row"}>
              <div className={"col-xs-12"}>
                <Form.Group>
                  <Form.Label><FormattedMessage id={"new_broadcast.recipients"} defaultMessage={"Ontvangers"} /></Form.Label>
                  <Form.Control
                    value={this.state.recipients}
                    onChange={(e) => this.setState({recipients: e.target.value})}
                    placeholder={this.props.intl.formatMessage({id: "new_broadcast.recipients_placeholder"})}
                    tabIndex={"6"}
                  />

                  <div className={"recipients-upload-buttons m-t-10"}>
                    <input
                      type="file"
                      className={"custom-file-upload"}
                      name={"custom-file-upload"}
                      id={"custom-file-upload"}
                      accept={".csv,.txt"}
                      onChange={(e) => this.handleFileChosen(e.target.files[0])}
                      ref={this.fileUpload}
                    />
                    <label htmlFor="custom-file-upload" className={"btn btn-primary"}>
                      <i className={"fa fa-upload m-r-5"} /> {this.state.file ? this.state.file.name + " (" + this.state.recipientsInFile.length + " " + this.props.intl.formatMessage({id: "new_broadcast.email_addresses"}) + ")" : this.props.intl.formatMessage({id: "new_broadcast.no_file_chosen"})}
                    </label>
                    {this.state.file &&
                      <span
                        className={"fa fa-remove remove-file-button m-l-10"}
                        onClick={() => {
                          this.setState({
                            file: null,
                            recipientsInFile: []
                          }, () => {
                            this.fileUpload.current.value = ""
                          })
                        }}
                      />}
                  </div>

                  <span><FormattedMessage id={"new_broadcast.file_info"} defaultMessage={"Het bestand met ontvangers is een *.csv of een *.txt bestand. Alle e-mailadressen in dit bestand zullen automatisch toegevoegd worden aan de verzendlijst. Het uploaden van een bestand is optioneel."} /></span>
                </Form.Group>
              </div>
            </div>

            <hr />

            <div className={"row"}>
              <div className={"col-xs-12"}>
                <Form.Group>
                  <Form.Label><FormattedMessage id={"new_broadcast.title"} defaultMessage={"Titel"} /></Form.Label>
                  <Form.Control
                    value={this.state.title}
                    onChange={(e) => this.setState({title: e.target.value})}
                    placeholder={this.props.intl.formatMessage({id: "new_broadcast.title_placeholder"})}
                    tabIndex={"7"}
                  />
                </Form.Group>

                <Form.Group>
                  <Form.Label><FormattedMessage id={"new_broadcast.message"} defaultMessage={"Bericht"} /></Form.Label>
                  <Form.Control
                    value={this.state.message}
                    onChange={(e) => this.setState({message: e.target.value})}
                    placeholder={this.props.intl.formatMessage({id: "new_broadcast.message_placeholder"})}
                    as={"textarea"}
                    rows={"10"}
                    tabIndex={"8"}
                  />
                </Form.Group>

                <Button className="btn btn-secondary" onClick={() => this.setState({displayEmailPreview: true})}>
                  <i className={"fa fa-eye"} /> Preview
                </Button>
              </div>
            </div>

            {this.state.displayColorPicker ?
              <div className="settings-popover">
                <div className="settings-cover" onClick={ this.handleClose }/>
                <SketchPicker color={ this.state.buttonColor } onChange={ this.handleColorChange } />
              </div>
              : null }

            {this.state.displayEmailPreview &&
              <EmailPreviewDialog
                title={this.state.title}
                surveyId={this.state.surveyId}
                message={this.state.message}
                buttonColor={this.state.buttonColor}
                onClose={() => this.setState({displayEmailPreview: false})} />
            }

            <br /><br />
            <Button className="btn btn-primary" onClick={() => this.saveMailing()}>
              <i className="fa fa-save"/> <FormattedMessage id={"ui.save"} defaultMessage={"Opslaan"} />
            </Button>
            <NavLink to={'/verzendlijsten'}>
              <Button className="btn btn-secondary m-l-5"> <FormattedMessage id={"ui.cancel"} defaultMessage={"Annuleren"} /></Button>
            </NavLink>
          </div>
        </div>
      </DashboardFrame>
    )
  }
}

export default withRouter(injectIntl(NewMailing))
