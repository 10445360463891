import Accordion from "react-bootstrap/Accordion"
import Card from "react-bootstrap/Card"
import Button from "react-bootstrap/Button"
// import DashboardFrame from "../../../components/Dashboard/DashboardFrame/DashboardFrame"
import React from "react"
import axios from 'axios'
import {Dropdown} from "semantic-ui-react"
import {Form} from "react-bootstrap"

const {rootUrl} = require("../../../config")

const fetchData = (id, callback) => {
  axios.get(rootUrl + '/api/v1/benchmark/clusters_with_surveys/' + id)
    .then((res) => {
      console.log(res)
      let group = res.data.reduce((r, a) => {
        if(!r[a.cluster_name]) {
          r[a.cluster_name] = {
            surveys: [],
            cluster_id: a.cluster_id
          }
        }
        if(a.survey_name !== null) {
          r[a.cluster_name].surveys = [...r[a.cluster_name].surveys || [], a];
        }

        return r;
      }, {});
      callback(group)

    })
    .catch((e) => {
      console.log(e)
    })
}

const getClusters = (callback) => {
  axios.get(rootUrl + '/api/v1/benchmark/clusters')
    .then((res) => {
      callback(res.data)
    })
    .catch(e => console.log(e))
}

const getSurveys = (callback) => {
  axios.post(rootUrl + '/api/v1/dashboard/projectssummary')
    .then(res => {
      callback(res.data.surveys)
    })
    .catch(e => {
      console.log(e)
      callback([])
    })
}

export default function Clusters(props) {
  const [id] = React.useState(props.id)
  const [clusters, setClusters] = React.useState([])
  const [possibleClusters, setPossibleClusters] = React.useState([])
  const [possibleSurveys, setPossibleSurveys] = React.useState([])
  const [loading, setLoading] = React.useState(true)
  const [selectedCluster, setSelectedCluster] = React.useState(null)
  const [selectedSurvey, setSelectedSurvey] = React.useState(null)
  const [newClusterName, setNewClusterName] = React.useState('')

  if(loading) {
    fetchData(id, (results) => {
      setClusters(results)
      getClusters((res) => {
        setPossibleClusters(res)
        getSurveys((surveys) => {
          setPossibleSurveys(surveys)
          setLoading(false)
        })

      })
    })
  }

  const removeFromCluster = (i, j, survey_id, cluster_id) => {
    axios.post(rootUrl + '/api/v1/benchmark/remove_survey_from_cluster', {survey_id, cluster_id})
      .then(() => {
        let temp = {...clusters}
        temp[i].surveys.splice(j, 1)
        setClusters(temp)
      })
      .catch(e => {
        console.log(e)
      })
  }

  const addSelectedCluster = () => {
    axios.post(rootUrl + '/api/v1/benchmark/add_cluster_to_setting', {id, selectedCluster})
      .then((res) => {
        let group = res.data.reduce((r, a) => {
          if(!r[a.cluster_name]) {
            r[a.cluster_name] = {
              surveys: [],
              cluster_id: a.cluster_id
            }
          }
          if(a.survey_name !== null) {
            r[a.cluster_name].surveys = [...r[a.cluster_name].surveys || [], a];
          }

          return r;
        }, {});
        setClusters(group)
      })
      .catch(e => console.log(e))
  }

  const addSurveyToCluster = (cluster_id) => {
    axios.post(rootUrl + '/api/v1/benchmark/add_survey_to_cluster', {cluster_id, survey_id: selectedSurvey, settings_id: id})
      .then(res => {
        let group = res.data.reduce((r, a) => {
          if(!r[a.cluster_name]) {
            r[a.cluster_name] = {
              surveys: [],
              cluster_id: a.cluster_id
            }
          }
          if(a.survey_name !== null) {
            r[a.cluster_name].surveys = [...r[a.cluster_name].surveys || [], a];
          }

          return r;
        }, {});
        setClusters(group)
      })
      .catch(e => console.log(e))
  }

  const saveNewCluster = () => {
    newClusterName.length > 0 && axios.post(rootUrl + '/api/v1/benchmark/create_new_cluster', {newClusterName, settings_id: id})
      .then((res) => {
        let group = res.data.reduce((r, a) => {
          if(!r[a.cluster_name]) {
            r[a.cluster_name] = {
              surveys: [],
              cluster_id: a.cluster_id
            }
          }
          if(a.survey_name !== null) {
            r[a.cluster_name].surveys = [...r[a.cluster_name].surveys || [], a];
          }

          return r;
        }, {});
        setClusters(group)
        setNewClusterName('')
      })
      .catch(e => console.log(e))
  }

  const removeClusterFromSettings = (clusterId) => {
    axios.post(rootUrl + '/api/v1/benchmark/remove_cluster_from_settings', {settings_id: id, cluster_id: clusterId})
      .then(res => {
        let group = res.data.reduce((r, a) => {
          if(!r[a.cluster_name]) {
            r[a.cluster_name] = {
              surveys: [],
              cluster_id: a.cluster_id
            }
          }
          if(a.survey_name !== null) {
            r[a.cluster_name].surveys = [...r[a.cluster_name].surveys || [], a];
          }

          return r;
        }, {});
        setClusters(group)
      })
      .catch(e => console.log(e))
  }

  return (
        <div className="col-12">
          <h4 className="header-title m-t-0">Clusters {id}</h4>
          <p className="text-muted font-13 m-b-30">Hier vindt u een lijst met clusters. Door op een cluster te klikken, krijgt u inzicht in welke ziekenhuizen er binnen dit cluster vallen.</p>
          <Accordion>
            {
              Object.keys(clusters).map((key, i) => {
                console.log(clusters)
                return (
                  <Card key={i}>
                    <Card.Header>
                      <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                        <Accordion.Toggle as={Button} variant="link" eventKey={i}>
                          {key}
                        </Accordion.Toggle>
                        <Button variant={'link'} style={{color: 'red'}} onClick={() => removeClusterFromSettings(clusters[key].cluster_id)}>verwijderen</Button>
                      </div>

                    </Card.Header>
                    <Accordion.Collapse eventKey={i}>
                      <Card.Body>
                        {clusters[key].surveys.map((survey, j) => {
                          return (<div className='row' style={{marginLeft: 10, display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}} key={j}>
                            <p style={{margin: 8}}>
                              {survey.survey_internal_name + " (" + survey.survey_name + ")"}
                            </p>
                            <Button variant={'link'} style={{color: 'red'}} onClick={() => removeFromCluster(key, j, survey.survey_id, survey.cluster_id)}>verwijderen</Button>
                          </div>)
                        })}
                        <hr style={{margin: 10}}/>
                        <div style={{margin: 10}}>
                          <span className={"cluster-label"}> voeg een organisatie toe </span>
                          <Dropdown
                            placeholder='Selecteer een organisatie...'
                            selection
                            options={possibleSurveys.map((option, index) => {
                              return {key: index, text: option.survey_name + ' (' + option.project_name + ')', value: option.survey_id}
                            })}
                            value={selectedSurvey}
                            onChange={(e, {value}) => setSelectedSurvey(value)}
                          />
                          <Button style={{marginLeft: 10}} onClick={() => addSurveyToCluster(clusters[key].cluster_id)}>+</Button>
                        </div>


                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                )
              })
            }
          </Accordion>
          <span className={"cluster-label"}> voeg een bestaand cluster toe aan deze instelling </span>
          <Dropdown
            placeholder='Selecteer een cluster...'
            selection
            options={possibleClusters.map((option, index) => {
              return {key: index, text: option.cluster_name, value: option.cluster_id}
            })}
            value={selectedCluster}
            onChange={(e, {value}) => setSelectedCluster(value)}
          />
          <Button style={{marginLeft: 10}} onClick={addSelectedCluster}>+</Button>
          <hr style={{marginTop: 10}}/>
          <Form.Group>
            <Form.Label>Maak een nieuw cluster aan</Form.Label>
            <div style={{display: 'flex', flexDirection: 'row', height: 40}}>
              <Form.Control
                value={newClusterName}
                onChange={(e) => setNewClusterName(e.target.value)}
                placeholder="Clusternaam"
              />
              <Button variant={'success'} style={{marginLeft: 5}} onClick={saveNewCluster}>Toevoegen</Button>
            </div>
          </Form.Group>



        </div>
  )
}
